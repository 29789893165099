import { ButtonBar, Flex } from '@cfx-dev/ui-components';
import { observer } from 'mobx-react-lite';

import { LinkButton } from 'cfx/ui/Button/LinkButton';

import { HomeButton } from './HomeButton/HomeButton';

interface INavBarLink {
  href: string;
  label: string;
}

const navBarLinks: INavBarLink[] = [
  {
    href: 'https://forum.cfx.re',
    label: 'Forum',

  },
  {
    href: 'https://discord.gg/fivem',
    label: 'Discord',

  },
  {
    href: 'https://docs.fivem.net/docs/',
    label: 'Docs',

  },
  {
    href: '/servers',
    label: 'Servers',

  },
  {
    href: 'https://cookbook.fivem.net/',
    label: 'Cookbook',

  },
  {
    href: 'https://github.com/citizenfx/fivem',
    label: 'Github',

  },
  {
    href: 'https://fivem.net/terms',
    label: 'Terms',

  },
  {
    href: 'https://portal.cfx.re',
    label: 'Portal',

  },
];

export const NavBar = observer(function NavBarLinks() {
  const linkNodes = navBarLinks
    .map(({
      href, label,
    }) => (
      <LinkButton
        target={label === 'Servers'
          ? '_self'
          : '_blank'}
        key={label}
        to={href}
        text={label}
        size="large"
        theme="transparent"
      />
    ));

  return (
    <Flex fullWidth spaceBetween gap="large">
      <HomeButton />
      <ButtonBar>{linkNodes}</ButtonBar>
    </Flex>
  );
});
